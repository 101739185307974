import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Nearly 2km above Brixen we stand on top of the Plose. A breathtaking view
down into the Eisack valley and into the Dolomites opens up. Luckily we didn't
have to fight up all the 2000 vertical metres by ourselfes, but got support by
some tiny gondolas.`}</p>
    <br />
    <p>{`Right at the top station of the lift, the Brixen Bikepark begins. Three
different lines up to a length of 4,7km wind down the southface. Different
difficulties allow less experienced riders to slowly progress.`}</p>
    <br />
    <p>{`The Palmschoß chairlift brings you up quickly back to the top. To step it up
you can ride the very long and a bit more difficult Skyline underneath the
Plose Lift with it's endles amount of berms.`}</p>
    <TrailguidePlugin content="lat=46.6956&lng=11.7080&coverage=5" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,46.6956,11.7080"
        }}>{`web-app`}</a></p>
    </p>
    <p>{`Bike park style trails are not for everyone, and luckily we also have
possibilities there. So we stand on the 2486m high Plose and prepare for a
11km long descent in alpine terrain. The trail follows the ridge towards the
Ochsenalm. Narrow, loose in the beginning and with some switchbacks in the
middle part. From the Ochsenalm a long traverse begins and then drops into
the steep forest. Good control and technique is required for the steep trail
with many roots, steps and rocks. But you get rewarded with an endless
descent back down to the Plose gondola.`}</p>
    <br />
    <br />
    <h4>{`Skyline`}</h4>
    <p>{`An endless amount of berms take you back down to the bottom station of
the Plose lift.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1925"
      }}>{`here`}</a></p>
    <Image src="destinations/brixen_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Jerry Line`}</h4>
    <p>{`Easy flow line for less experienced riders.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3377"
      }}>{`here`}</a></p>
    <Image src="destinations/brixen_13.jpg" mdxType="Image" />
    <br />
    <h4>{`Hammer Line`}</h4>
    <p>{`More jumps and tighter corners make the Hammer Line.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3376"
      }}>{`here`}</a></p>
    <Image src="destinations/brixen_7.jpg" mdxType="Image" />
    <br />
    <h4>{`Palm Pro Line`}</h4>
    <p>{`The most challenging Line around the Palmschoß lift.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3378"
      }}>{`here`}</a></p>
    <Image src="destinations/brixen_8.jpg" mdxType="Image" />
    <br />
    <h4>{`Palmschoß`}</h4>
    <p>{`The trails around the Palmschoß chairlift wind down in front of the Dolomites.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3378"
      }}>{`here`}</a></p>
    <Image src="destinations/brixen_10.jpg" mdxType="Image" />
    <br />
    <h4>{`Plose Panorama`}</h4>
    <p>{`For the experienced riders, the Plose offers long and alpine descents also.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/1924"
      }}>{`here`}</a></p>
    <Image src="destinations/brixen_2.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      